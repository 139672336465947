<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">项目来源</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'ProjectSource'"
            :width="'100'"
            :introductionType="'projectManager'"
            :yearIsShow="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.projectSourcePrincipal"
            v-model="sectionInfo.projectSourceYear"
            @change="onProjectSourceChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :type="'ProjectSource'"
            :width="'100'"
            :introductionType="'projectManager'"
            :title="titlePrincipal"
            :list="userList"
            :yearValue="sectionInfo.projectSourceYear"
            v-model="sectionInfo.projectSourcePrincipal"
            @change="onProjectSourceChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onProjectSourceImg"
          >数据来源说明</el-button
        >
      </div>
      <div class="statistics_top">
        <el-card style="width: 49.7%"
          ><span class="title_fw">我方合同金额</span>
          <ProjectSource :projectSourceList="projectSourceList" />
        </el-card>
        <el-card style="width: 49.7%"
          ><span class="title_fw">收款金额</span>
          <PaymentProjectSource :projectSourceList="projectSourceList" />
        </el-card>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <span class="title_fw">项目来源统计</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'ProjectSourceData'"
            :width="'100'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'projectSource'"
            :yearIsShow="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.projectSourceDataPrincipal"
            :threeValue="sectionInfo.projectSource"
            v-model="sectionInfo.projectSourceDataYear"
            @change="onProjectSourceDataChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :type="'ProjectSourceData'"
            :width="'100'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'projectSource'"
            :title="titlePrincipal"
            :list="userList"
            :yearValue="sectionInfo.projectSourceDataYear"
            :twoValue="sectionInfo.projectSourceDataPrincipal"
            :threeValue="sectionInfo.projectSource"
            v-model="sectionInfo.projectSourceDataPrincipal"
            @change="onProjectSourceDataChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :threeIsShow="true"
            :title="'项目来源：'"
            :width="'150'"
            :type="'ProjectSourceData'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'projectSource'"
            :list="dictData.projectSource"
            :yearValue="sectionInfo.projectSourceDataYear"
            :twoValue="sectionInfo.projectSourceDataPrincipal"
            :threeValue="sectionInfo.projectSource"
            v-model="sectionInfo.projectSource"
            @change="onProjectSourceDataChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onProjectSourceDataImg"
          >数据来源说明</el-button
        >
      </div>
      <el-table
        height="330px"
        :summary-method="getSummaries"
        show-summary
        border
        ref="table"
        row-key="id"
        :data="projectSourceData"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="projectName"
          label="项目名称"
          align="center"
          fixed="left"
          min-width="240"
        ></el-table-column>
        <el-table-column
          prop="projectManager"
          label="项目经理"
          align="center"
          fixed="left"
          min-width="90"
        ></el-table-column>
        <el-table-column
          prop="contractingCompany"
          min-width="110"
          label="承接公司"
          sortable
          align="center"
        >
          <template slot-scope="{}" slot="header">
            <span>承接公司</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的承接项目公司</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.contractingCompany | dict(dictData.companyType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="projectSource"
          min-width="110"
          label="项目来源"
          sortable
          align="center"
        >
          <template slot-scope="{}" slot="header">
            <span>项目来源</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的项目来源</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.projectSource | dict(dictData.projectSource) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="predictContractAmount"
          min-width="130"
          label="我方合同金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>我方合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.predictContractAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="contractAmount"
          min-width="200"
          label="合同实际金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>合同实际金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>金额前面有（大），该项目即为大包合同子项</div>
                <div>项目详情里的合同实际金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.isLargePackage == 1 ? '（大）' : '' }}
            {{ scope.row.contractAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="actualAmount"
          min-width="130"
          label="项目实际金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>项目实际金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的项目实际金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="invoiceAmount"
          min-width="130"
          label="已开票金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>已开票金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已开票金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.invoiceAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="repaymentAmount"
          min-width="130"
          label="已收款金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>已收款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.repaymentAmount | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          v-show="imgUrl == '项目统计-项目来源'"
          src="@/assets/项目统计-项目来源.png"
          alt="数据来源说明"
          width="100%"
        />
        <img
          v-show="imgUrl == '项目统计-项目来源统计'"
          src="@/assets/项目统计-项目来源统计.png"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    ProjectSource: () => import('./projectSource.vue'),
    PaymentProjectSource: () => import('./paymentProjectSource.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      userList: [], // 项目经理
      dictData: {
        yearList: [],
        projectSource: [],
        companyType: [],
      },
      projectSourceList: [], // 项目来源
      projectSourceData: [],
      sectionInfo: {
        // 条件筛选 // 年份
        projectSourceYear: null,
        projectSourceDataYear: null,
        // 项目经理
        projectSourcePrincipal: '',
        projectSourceDataPrincipal: '',
        projectSource: '',
      },
      titleYear: '统计时间',
      titlePrincipal: '项目经理',
      isDialog: false,
      imgUrl: '',
      contractAmount: 0,
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  updated() {
    this.$refs.table.doLayout()
  },
  methods: {
    onProjectSourceImg() {
      this.imgUrl = '项目统计-项目来源'
      this.isDialog = true
    },
    onProjectSourceDataImg() {
      this.imgUrl = '项目统计-项目来源统计'
      this.isDialog = true
    },
    onProjectSourceDataChange(obj) {
      this.projectSourceData = obj?.projectSourceResponseLists || []
      this.contractAmount = obj?.projectPaymentAmount || 0
    },
    onProjectSourceChange(arr) {
      this.projectSourceList = arr
    },
    async getType() {
      const projectSource = await this.$api.dict.listSysDictData('PROJECT_SOURCE', true)
      this.dictData.projectSource = projectSource.data
      const item = this.dictData.projectSource.some(v => v.id === '1')
      if (!item) {
        this.dictData.projectSource.unshift({
          id: '1',
          dictName: '全部',
          dictVal: '',
        })
      }
      this.loading = true
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.projectSourceYear = dictVal
          this.sectionInfo.projectSourceDataYear = dictVal

          const queryProjectSourceSummary = await this.$api.statistics.queryProjectSourceSummary(
            `date=${dictVal}`
          )
          const projectQueryProjectSourceSummaryList =
            await this.$api.statistics.projectQueryProjectSourceSummaryList(`date=${dictVal}`)

          this.projectSourceList = queryProjectSourceSummary.data?.projectSourceResponse.map(
            item => {
              const projectSourceItem = projectSource.data.find(
                v => v.dictVal === item.projectSourceName
              )
              return {
                projectSourcePaymentAmount: item.projectSourcePaymentAmount,
                projectSourceAmount: item.projectSourceAmount,
                projectPercentage: Number((item.projectPercentage * 100).toFixed(2)),
                paymentProjectPercentage: Number((item.paymentProjectPercentage * 100).toFixed(2)),
                projectSourceCount: item.projectSourceCount,
                name: projectSourceItem ? projectSourceItem.dictName : item.projectSourceName, // 如果找不到则使用原名称
              }
            }
          )
          this.projectSourceData =
            projectQueryProjectSourceSummaryList?.data?.projectSourceResponseView
              ?.projectSourceResponseLists || []
          this.contractAmount =
            projectQueryProjectSourceSummaryList?.data?.projectSourceResponseView
              ?.projectPaymentAmount || 0
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
      this.$api.project
        .getProjectInfoByStaffId()
        .then(res => {
          this.userList = res.data?.projectManagerNameRespList.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.userList.unshift({
            id: `${Math.random()}`,
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index > 4) {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)

            if (index === 6) {
              sums[index] = Number(this.contractAmount.toFixed(2)).toLocaleString()
            } else {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        } else {
          sums[index] = '--'
        }
      })
      return sums
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
